/* eslint-disable @scandipwa/scandipwa-guidelines/create-config-files */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { ProductType } from '@scandipwa/scandipwa/src/type/ProductList.type';

import LinkedProductsSlider from './LinkedProductsSlider.component';

export const listName = 'related products';

export const GTMDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/GTM/GTM.dispatcher'
);

/** @namespace Pwa/Component/LinkedProductsSlider/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    linkedProducts: state.LinkedProductsReducer.linkedProducts
});

/** @namespace Pwa/Component/LinkedProductsSlider/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    pushProductImpressions: (products, list) => GTMDispatcher.then(
        ({ default: dispatcher }) => dispatcher.pushProductImpressions(dispatch, products, list)
    )
});

/** @namespace Pwa/Component/LinkedProductsSlider/Container/LinkedProductsSliderContainer */
export class LinkedProductsSliderContainer extends PureComponent {
    static propTypes = {
        linkedProducts: PropTypes.objectOf(ProductType).isRequired,
        linkType: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        pushProductImpressions: PropTypes.func.isRequired
    };

    static propTypes = {
    };

    containerFunctions = {

    };

    componentDidMount() {
        const {
            linkType,
            linkedProducts: {
                [linkType]: {
                    items = []
                } = {}
            },
            pushProductImpressions
        } = this.props;

        if (items.length > 0) {
            pushProductImpressions(items, listName);
        }
    }

    componentDidUpdate(prevProps) {
        const {
            linkType,
            linkedProducts: {
                [linkType]: {
                    items: newLinkedProducts = []
                } = {}
            },
            pushProductImpressions
        } = this.props;

        const {
            linkedProducts: {
                [linkType]: {
                    items: oldLinkedProducts = []
                } = {}
            }
        } = prevProps;

        const { sku: oldFirstSku } = oldLinkedProducts[0] || {};
        const { sku: newFirstSku } = newLinkedProducts[0] || {};
        if (newFirstSku !== oldFirstSku) {
            pushProductImpressions(newLinkedProducts, listName);
        }
    }

    containerProps = () => {
        const { linkedProducts, linkType, title } = this.props;
        return {
            linkedProducts,
            linkType,
            title
        };
    };

    render() {
        return (
            <LinkedProductsSlider
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LinkedProductsSliderContainer);
