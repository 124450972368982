/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable no-magic-numbers */
/* eslint-disable no-undef */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable max-lines */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable max-len */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import { ProductType } from '@scandipwa/scandipwa/src/type/ProductList.type';
// import BrowserDatabase from '@scandipwa/scandipwa/src/util/BrowserDatabase';
import axios from 'axios';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import {
    AVAILABILITY_INFO_BOX,
    ONE_DAY_IN_MILLISECONDS,
    ONE_SECOND_IN_MILLISECONDS
} from 'Component/McAvailability/McAvailability.config';
import Popup from 'Component/Popup';
import PopupSuspense from 'Component/PopupSuspense';
import ProductCapBox from 'Component/ProductCapBox';
import TextPlaceholder from 'Component/TextPlaceholder';
import {
    ADO_AVAILABILITY_POPUP,
    CATEGORY_ID_ANGOLO_DELLE_OCCASIONI,
    QUANTITA_ADO_API
} from 'Route/AngoloDelleOccasioni/AngoloDelleOccasioni.config';
import { WarehouseType } from 'Type/WarehouseType';

import './McAvailability.style';

/** @namespace Pwa/Component/McAvailability/Component/McAvailabilityComponent */
export class McAvailabilityComponent extends PureComponent {
    static propTypes = {
        mcAvailability: PropTypes.shape({
            description: PropTypes.string,
            timestamp: PropTypes.number
        }).isRequired,
        sku: PropTypes.string,
        warehouse: WarehouseType.isRequired,
        resetWarehouse: PropTypes.func.isRequired,
        product: ProductType.isRequired,
        showPopup2: PropTypes.func.isRequired,
        updateProduct: PropTypes.func.isRequired
    };

    static defaultProps = {
        sku: undefined
    };

    state = {
        isWarehouseSelectorOpen: false,
        ado_availability_from: '',
        ado_availability_from_formatted: '',
        min_qty: 0
    };

    renderOverlayFallback() {
        const { sku } = this.props;
        return (
            <PopupSuspense
              actualOverlayKey={ sku }
            />
        );
    }

    getQuantityAndAvailability(sku, warehouse, retryCount = 0) {
        axios.post(QUANTITA_ADO_API, {
            skus: sku,
            cap: '',
            warehouse: warehouse?.value?.code
        }).then(
            /** @namespace Pwa/Component/McAvailability/Component/post/then */
            (quantitaAdoResponse) => {
                this.setState({ min_qty: quantitaAdoResponse.data[0].quantity });
                axios.post('/rest/V1/mc/ado/getCartQuantityAdo', {
                    qty: quantitaAdoResponse.data[0].quantity,
                    sku,
                    warehouse: warehouse?.value?.code
                }).then(
                    /** @namespace Pwa/Component/McAvailability/Component/post/then */
                    (response) => {
                        this.setState({ ado_availability_from: response.data });
                        const date = new Date(this.state.ado_availability_from * 1000);

                        // Estrai giorno, mese e anno dalla data
                        const day = date.getDate().toString().padStart(2, '0');
                        const month = (date.getMonth() + 1).toString().padStart(2, '0');
                        const year = date.getFullYear();

                        // Formatta la data nel formato desiderato
                        const formattedDate = `${day}/${month}/${year}`;
                        this.setState({ ado_availability_from_formatted: formattedDate });
                    }
                ).catch(
                    /** @namespace Pwa/Component/McAvailability/Component/post/then/catch */
                    (error) => {
                        if (error.response && error.response.status === 400 && retryCount < 7) {
                            this.getQuantityAndAvailability(sku, warehouse, retryCount + 1);
                        }
                    }
                );
            }
        ).catch(
            /** @namespace Pwa/Component/McAvailability/Component/post/then/catch */
            (error) => {
                if (error.response && error.response.status === 400 && retryCount < 7) {
                    this.getQuantityAndAvailability(sku, warehouse, retryCount + 1);
                }
            }
        );
    }

    renderWarehouseSelector() {
        return (
            <ProductCapBox onChange={ this.closeSelector } />
        );
    }

    openSelector = () => {
        const {
            resetWarehouse, product, product: { categories }, showPopup2
        } = this.props;
        const isADO = categories
        && categories.find((elem) => elem.id === CATEGORY_ID_ANGOLO_DELLE_OCCASIONI) !== undefined;

        const isOutOfStockADO = product.categories.find((elem) => elem.id === CATEGORY_ID_ANGOLO_DELLE_OCCASIONI)
            && product.quantita?.value === 0;

        if (isADO && !isOutOfStockADO) {
            showPopup2(ADO_AVAILABILITY_POPUP);
        } else {
            resetWarehouse();
            this.setState({ isWarehouseSelectorOpen: true });
        }
    };

    closeSelector = () => {
        this.setState({ isWarehouseSelectorOpen: false });
    };

    renderCurrentWarehouse() {
        const { warehouse } = this.props;
        if (!warehouse) {
            return null;
        }

        return (
            <div block="McAvailability" elem="CurrentWarehouse">
                <div block="McAvailability" elem="CurrentWarehouseLabel">{ warehouse?.label }</div>
                <button
                  block="McAvailability"
                  elem="CurrentWarehouseSwitch"
                  onClick={ this.openSelector }
                >
                    { __('Cambia') }
                </button>

            </div>
        );
    }

    renderWarehouse() {
        const { isWarehouseSelectorOpen } = this.state;
        const { warehouse } = this.props;

        return (
            <>
            { !isWarehouseSelectorOpen && this.renderCurrentWarehouse() }
            { (!warehouse || isWarehouseSelectorOpen) && this.renderWarehouseSelector() }
            </>
        );
    }

    renderInfoPopup() {
        return (
            <Popup
              id={ AVAILABILITY_INFO_BOX }
              clickOutside
            >
                { this.renderInfoContent() }
            </Popup>
        );
    }

    renderPopupADO() {
        const { product: { name }, resetWarehouse, updateProduct } = this.props;

        return (
            <Popup
              id={ ADO_AVAILABILITY_POPUP }
              clickOutside
              customClass="Availability-ADO"
            >
                <div>
                    <p className="title">{ __('Warning') }</p>
                    <p className="desc">
                        { __('ADOAvailabilityPopup.desc1') }
                        { ' ' }
                        <b>{ name }</b>
                        { ' ' }
                        { __('ADOAvailabilityPopup.desc2') }
                        <b>{ __('ADOAvailabilityPopup.desc3') }</b>
                        { ' ' }
                        { __('ADOAvailabilityPopup.desc4') }
                    </p>
                    <button className="back" onClick={ () => document.body.click() }>{ __('Go back') }</button>
                    <button
                      className="edit"
                      onClick={ async () => {
                          document.body.click();
                          await resetWarehouse();
                          this.setState({ isWarehouseSelectorOpen: true });
                          updateProduct();
                      } }
                    >
                        { __('Edit address') }
                    </button>
                </div>
            </Popup>
        );
    }

    renderInfoContent() {
        return (
            <span className="DisponibilitaNotice">
            { __('Indicative data.') }
                <br />
                { __('Enter your ZIP code in the cart to find out '
                    + 'the first availability of the product in your area.') }
            </span>
        );
    }

    renderNoWarehouse() {
        return (
            <div className="DisponibilitaWrapper">
                <dd className="DisponibilitaText">
                            <span>
                                { __('Inserisci il cap per conoscere la disponibilità') }
                            </span>
                    <br />
                    { this.renderWarehouse() }
                </dd>
            </div>
        );
    }

    renderWithWarehouse() {
        const {
            mcAvailability: { description: rawDescription, timestamp }, product: { categories }, product, warehouse
        } = this.props;
        const now = new Date().getTime();
        const deltaTime = now - timestamp * ONE_SECOND_IN_MILLISECONDS;
        const immediateAvailability = (deltaTime > 0 && deltaTime < ONE_DAY_IN_MILLISECONDS);
        const description = immediateAvailability ? __('Immediate availability') : rawDescription;

        const isADO = categories
        && categories.find((elem) => elem.id === CATEGORY_ID_ANGOLO_DELLE_OCCASIONI) !== undefined;

        if (!isADO) {
            return (
                <div className="DisponibilitaWrapper">
                    <dd className="DisponibilitaText">
                                { !immediateAvailability && (
                                    <span>
                                    { __('Available from:') }
    &nbsp;
                                    </span>
                                ) }
                        <strong>
                            <TextPlaceholder
                              content={ timestamp === 0 ? __('Contattare servizio clienti') : description }
                            />
                        </strong>
                        <br />
                        { this.renderWarehouse() }
                        { this.renderPopupADO() }
                    </dd>
                </div>
            );
        }
        if (isADO) {
            const { sku } = product;
            // const warehouseCode = BrowserDatabase.getItem('mc_warehouse')?.value?.code !== undefined ? BrowserDatabase.getItem('mc_warehouse')?.value?.code : ' ';
            this.getQuantityAndAvailability(sku, warehouse);

            return (
                <div className="DisponibilitaWrapper">
                    <dd className="DisponibilitaText">
                    { this.state.min_qty > 0 && (
                                    <span>
                                    { __('Available from:') }
    &nbsp;
                                    </span>
                    ) }
                    { this.state.min_qty > 0 && (
                      <strong>
                      <TextPlaceholder
                        content={ timestamp === 0 ? __('Contattare servizio clienti') : this.state.ado_availability_from_formatted }
                      />
                      </strong>
                    ) }

                        <br />
                        { this.renderWarehouse() }
                        { this.renderPopupADO() }
                    </dd>
                </div>
            );
        }

        return null;
    }

    render() {
        const { warehouse } = this.props;

        return (
            <section
              block="ProductActions"
              elem="Section"
              mods={ { type: 'Disponibilita' } }
            >
                { warehouse && this.renderWithWarehouse() }
                { !warehouse && this.renderNoWarehouse() }
            </section>
        );
    }
}

export default McAvailabilityComponent;
