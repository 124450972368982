import { connect } from 'react-redux';

import { showPopup } from 'Store/Popup/Popup.action';

import ProductTopInfoComponent from './ProductTopInfo.component';

/** @namespace Pwa/Component/ProductTopInfo/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    product: state.ProductReducer.product,
    device: state.ConfigReducer.device
});

/** @namespace Pwa/Component/ProductTopInfo/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showPopup: (id, payload) => dispatch(showPopup(id, payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductTopInfoComponent);
