/* eslint-disable jsx-a11y/control-has-associated-label */
import Image from 'Component/Image';
import {
    ProductGalleryThumbnailImageComponent as SourceProductGalleryThumbnailImage
} from 'SourceComponent/ProductGalleryThumbnailImage/ProductGalleryThumbnailImage.component';

import './ProductGalleryThumbnailImage.override.style';

/** @namespace Pwa/Component/ProductGalleryThumbnailImage/Component/ProductGalleryThumbnailImageComponent */
export class ProductGalleryThumbnailImageComponent extends SourceProductGalleryThumbnailImage {
    renderVideo() {
        const {
            media: {
                thumbnail: { url },
                label
            }
        } = this.props;

        return (
            <>
            <Image
              ratio="custom"
              src={ url }
              alt={ label }
              mix={ { block: 'ProductGalleryThumbnailImage' } }
            />
            <button block="ProductGalleryThumbnailImage" elem="PlayButton" />
            </>
        );
    }

    renderPlayIcon() {
        return (
            <span block="VideoThumbnail" elem="PlayIcon">
                { __('Play video') }
            </span>
        );
    }
}

export default ProductGalleryThumbnailImageComponent;
