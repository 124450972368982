/** @namespace Pwa/Route/ServizioClienti/Functions/utileClick */
export const utileClick = (e) => {
    const button = e.target;
    button.parentElement.classList.add('hide');
    if (button.classList[0] === 'si') {
        button.parentElement.nextElementSibling.classList.add('show');
    } else {
        button.parentElement.nextElementSibling.nextElementSibling.classList.add(
            'show'
        );
    }
};

/** @namespace Pwa/Route/ServizioClienti/Functions/removeElementArray */
export const removeElementArray = (arr, index) => {
    if (index === 0) {
        return arr.slice(1, arr.length);
    }

    return [...arr.slice(0, index), ...arr.slice(index + 1, arr.length)];
};

/** @namespace Pwa/Route/ServizioClienti/Functions/chatOpen */
export const chatOpen = () => {
    const chatButton = document
        .querySelector('#chatFrame')
        .contentDocument.querySelector('.chat-button');

    chatButton.click();
};
