/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable max-len */
import { connect } from 'react-redux';

import Popup from 'Component/Popup';
import {
    ImageZoomPopupContainer as SourceImageZoomPopupContainer,
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps
} from 'SourceComponent/ImageZoomPopup/ImageZoomPopup.container';

import ImageZoomPopup from './ImageZoomPopup.component';

/** @namespace Pwa/Component/ImageZoomPopup/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state)
    // TODO extend mapStateToProps
});

/** @namespace Pwa/Component/ImageZoomPopup/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch)
    // TODO extend mapDispatchToProps
});

/** @namespace Pwa/Component/ImageZoomPopup/Container/ImageZoomPopupContainer */
export class ImageZoomPopupContainer extends SourceImageZoomPopupContainer {
    render() {
        const {
            isActive,
            children,
            mix,
            popupId,
            onClose
        } = this.props;

        if (!isActive) {
            return children;
        }

        return (
            <Popup
              id={ popupId }
              clickOutside={ false }
              mix={ { block: 'ImageZoomPopup', mix } }
              contentMix={ { block: 'ImageZoomPopup', elem: 'PopupContent' } }
              onClose={ onClose }
              onHide={ onClose }
            >
                <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=4, user-scalable=yes" />
                <ImageZoomPopup
                  { ...this.props }
                />
            </Popup>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ImageZoomPopupContainer);
