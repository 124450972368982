/* eslint-disable fp/no-let */
/* eslint-disable prefer-template */
/* eslint-disable react/jsx-no-bind */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import Select from 'react-select';

import { ProductType } from '@scandipwa/scandipwa/src/type/ProductList.type';

import './SelectConfiguration.style';

/** @namespace Pwa/Component/SelectConfiguration/Component/SelectConfigurationComponent */
export class SelectConfigurationComponent extends PureComponent {
    static propTypes = {
        product: ProductType.isRequired,
        onChange: PropTypes.func.isRequired
    };

    render() {
        const {
            product: {
                mc_configurations, sku: productSKU, mc_techspecs_template,
                nuovo_template_option
            }, onChange
        } = this.props;

        const formatLabel = (sku, name, price, misureConf) => {
            if (sku === productSKU) {
                return name;
            }

            return (
                `<div class="row">
                    <div class="col-4">
                        ${name}
                    </div>
                    <div class="col-4">
                        ${misureConf}
                    </div>
                    <div style="text-align: right" class="col-4">
                        <strong>${price}&euro;</strong>
                    </div>
                </div>`
            );
        };

        const options = mc_configurations?.map((config) => {
            const {
                name, sku, url, price_delta, final_price, misure
            } = config;

            let price;
            let misureConf = '';

            // Se il prodotto in cui mi trovo è un armadio, per le sue varianti ricavo il prezzo finale (final_price) e le misure...
            // ...altrimenti solo la differenza di prezzo (price_delta)
            if (mc_techspecs_template === 'armadi') {
                price = final_price;
                misureConf = misure;
            } else {
                price = price_delta >= 0 ? ('+' + String(price_delta)) : String(price_delta);
            }
            const label = price !== undefined ? formatLabel(sku, name, price, misureConf) : name;

            return {
                label,
                value: sku,
                url
            };
        });

        console.log(options, "options")

        if (!options || !options.length) {
            return null;
        }

        const value = options[0];

        const formatOptionLabel = (data) => (
            // eslint-disable-next-line react/no-danger
            <span block="SelectConfiguration" elem="Option" dangerouslySetInnerHTML={{ __html: data.label }} />
        );

        return (
            <div block="SelectConfiguration">
                {nuovo_template_option.label === 'template_cucine_bloccate'
                    && <p>{__('Scegli quali elettrodomestici abbinare')}</p>}
                <Select
                    formatOptionLabel={formatOptionLabel}
                    isSearchable={false}
                    options={options}
                    value={value}
                    onChange={onChange}
                />
            </div>
        );
    }
}

export default SelectConfigurationComponent;
