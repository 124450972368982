// import PropTypes from 'prop-types';
import { PropTypes } from 'prop-types';
import { PureComponent } from 'react';

import './FieldHidden.style';

/** @namespace Pwa/Component/FieldHidden/Component/FieldHiddenComponent */
export class FieldHiddenComponent extends PureComponent {
    static propTypes = {
        value: PropTypes.number.isRequired,
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired
    };

    render() {
        const {
            value, id, name
        } = this.props;

        return (
            <input
              id={ id }
              name={ name }
              value={ value }
              type="hidden"
            />
        );
    }
}

export default FieldHiddenComponent;
