import {
    SliderComponent as SourceSlider
} from 'SourceComponent/Slider/Slider.component';

import './Slider.override.style';

/** @namespace Pwa/Component/Slider/Component/SliderComponent */
export class SliderComponent extends SourceSlider {
    // TODO implement logic
}

export default SliderComponent;
