// import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import openChat from 'Util/chat';

/** @namespace Pwa/Component/OpenChat/Container/OpenChatContainer */
export class OpenChatContainer extends PureComponent {
    componentDidMount() {
        openChat();
    }

    render() {
        return null;
    }
}

export default OpenChatContainer;
