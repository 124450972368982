export const METODO_CONSEGNA_CUSTOM_POPUP = 'METODO_CONSEGNA_CUSTOM_POPUP';
export const GARANZIA_CUSTOM_POPUP = 'GARANZIA_CUSTOM_POPUP';
export const SERVIZI_CUSTOM_POPUP = 'SERVIZI_CUSTOM_POPUP';
export const CATAS_CUSTOM_POPUP = 'CATAS_CUSTOM_POPUP';
export const COSMOB_CUSTOM_POPUP = 'COSMOB_CUSTOM_POPUP';
export const VARIANT_POPUP = 'VARIANT_POPUP';
export const COSMOB_MATERASSI_POPUP = 'COSMOB_MATERASSI_POPUP';
export const CATAS_MATERASSI_POPUP = 'CATAS_MATERASSI_POPUP';
export const GARANZIA_5 = 5;
export const GARANZIA_10 = 10;
export const GARANZIA_15 = 15;

export const DEFAULT_MAX_PRODUCTS = 99;
