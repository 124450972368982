/* eslint-disable max-len */
import BrowserDatabase from '@scandipwa/scandipwa/src/util/BrowserDatabase/BrowserDatabase';
import axios from 'axios';
import { connect } from 'react-redux';

import {
    GARANZIA_5,
    GARANZIA_10,
    GARANZIA_15
} from 'Component/ProductActions/ProductActions.config';
import { CATEGORY_ID_ANGOLO_DELLE_OCCASIONI, QUANTITA_ADO_API } from 'Route/AngoloDelleOccasioni/AngoloDelleOccasioni.config';
import {
    mapDispatchToProps as sourceMapDispatchToProps,
} from 'SourceComponent/Product/Product.container';
import {
    mapStateToProps as sourceMapStateToProps,
    ProductActionsContainer as SourceProductActionsContainer
} from 'SourceComponent/ProductActions/ProductActions.container';
import { adoLoading, changeQTY } from 'Store/AngoloDelleOccasioni/AngoloDelleOccasioni.action';
import { showPopup } from 'Store/Popup/Popup.action';

/** @namespace Pwa/Component/ProductActions/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    device: state.ConfigReducer.device,
    linkedProducts: state.LinkedProductsReducer.linkedProducts,
    thirdLevelQTY: state.AngoloDelleOccasioniReducer.thirdLevelQTY,
    isADOLoaded: state.AngoloDelleOccasioniReducer.isADOLoaded
});

/** @namespace Pwa/Component/ProductActions/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    showPopup: (id, payload) => dispatch(showPopup(id, payload)),
    updateProductDetailsQTY: (product) => dispatch(changeQTY(product)),
    adoLoading: (value) => dispatch(adoLoading(value))
});

/** @namespace Pwa/Component/ProductActions/Container/ProductActionsContainer */
export class ProductActionsContainer extends SourceProductActionsContainer {
    containerFunctions = {
        ...this.containerFunctions,
        // onProductValidationError: this.onProductValidationError.bind(this),
        // getIsOptionInCurrentVariant: this.getIsOptionInCurrentVariant.bind(this),
        // setGroupedProductQuantity: this._setGroupedProductQuantity.bind(this),
        // clearGroupedProductQuantity: this._clearGroupedProductQuantity.bind(this),
        // getIsConfigurableAttributeAvailable: this.getIsConfigurableAttributeAvailable.bind(this),
        getAnniGaranzia: this.getAnniGaranzia.bind(this),
        showPopup: this.props.showPopup,
    };

    getAnniGaranzia() {
        const { product: { gar5anni, gar10anni, gar15anni } } = this.props;

        if (gar5anni > 0) {
            return GARANZIA_5;
        }
        if (gar10anni > 0) {
            return GARANZIA_10;
        }
        if (gar15anni > 0) {
            return GARANZIA_15;
        }

        return 0;
    }

    async getapiUrl() {
        try {
            // Ottieni i dati dall'API
            const response = await fetch('/rest/V1/configurazioneRate/get/');
            const apiData = await response.json();

            return apiData;
        } catch (error) {
            return 'errore';
            // Gestisci l'errore secondo le tue esigenze
        }
    }

    async componentDidMount() {
        const configData = await this.getapiUrl();
        this.setState({ configData });
        const {
            product: { categories, sku }, updateProductDetailsQTY, adoLoading
        } = this.props;

        const isADO = categories && categories.find((elem) => elem.id === CATEGORY_ID_ANGOLO_DELLE_OCCASIONI) !== undefined;
        if (isADO && BrowserDatabase.getItem('mc_warehouse')) {
            const postalCode = BrowserDatabase.getItem('mc_warehouse')?.value?.zipcode;
            const warehouseCode = BrowserDatabase.getItem('mc_warehouse')?.value?.code !== undefined ? BrowserDatabase.getItem('mc_warehouse')?.value?.code : ' ';
            adoLoading(true);
            const response = await axios.post(QUANTITA_ADO_API, {
                skus: sku,
                cap: postalCode,
                warehouse: warehouseCode
            });

            const qty = parseInt(response.data[0].quantity, 10);
            updateProductDetailsQTY(qty);
            adoLoading(false);
        }
    }

    componentWillUnmount() {
        const { updateProductDetailsQTY } = this.props;
        updateProductDetailsQTY(undefined);
    }

    containerProps() {
        const { linkedProducts, thirdLevelQTY, isADOLoaded } = this.props;
        const { configData } = this.state;
        return {
            ...super.containerProps(),
            linkedProducts,
            configData,
            thirdLevelQTY,
            isADOLoaded
        };
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductActionsContainer);
