/* eslint-disable fp/no-let */
/* eslint-disable eqeqeq */
/* eslint-disable no-magic-numbers */
/* eslint-disable no-undef */
/* eslint-disable no-async-promise-executor */
/* eslint-disable max-len */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable max-lines */

import { MouseEvent, PureComponent, TouchEvent as ReactTouchEvent } from 'react';

import { ReactElement } from 'Type/Common.type';
import { noopFn } from 'Util/Common';

import { DraggableComponentProps, DraggableComponentState } from './Draggable.type';

import './Draggable.style';

/** @namespace Component/Draggable/Component */
export class DraggableComponent extends PureComponent {
    static defaultProps = {
        shiftX: 0,
        shiftY: 0,
        onDragStart: noopFn,
        onDragEnd: (state, callback) => {
            const { translateX, translateY } = state;

            callback({
                originalX: 0,
                originalY: 0,
                shiftX: translateX,
                shiftY: translateY,
            });
        },
        onClick: noopFn,
        onDrag: noopFn,
        handleFocus: noopFn,
        handleKey: noopFn,
        draggableRef: undefined,
        mix: {},
    };

    state = {
        isDragging: false,
        originalX: 0,
        translateX: 0,
        lastTranslateX: 0,
        originalY: 0,
        translateY: 0,
        lastTranslateY: 0,
    };

    __construct(props) {
        super.__construct?.(props);

        this.handleTouchStart = this.handleTouchStart.bind(this);
        this.handleMouseDown = this.handleMouseDown.bind(this);
        this.handleTouchMove = this.handleTouchMove.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleTouchEnd = this.handleTouchEnd.bind(this);
        this.handleMouseUp = this.handleMouseUp.bind(this);
        this.handleMouseMove = this.handleMouseMove.bind(this);
        this.handleDragEnd = this.handleDragEnd.bind(this);
    }

    static getDerivedStateFromProps(
        props,
        state,
    ) {
        const { shiftX, shiftY } = props;
        const { lastTranslateX, lastTranslateY } = state;

        if (shiftX !== lastTranslateX || shiftY !== lastTranslateY) {
            return {
                lastTranslateX: shiftX,
                lastTranslateY: shiftY,
            };
        }

        return null;
    }

    componentWillUnmount() {
        window.removeEventListener('mousemove', this.handleMouseMove);
        window.removeEventListener('mouseup', this.handleMouseUp);
        window.removeEventListener('touchmove', this.handleTouchMove);
        window.removeEventListener('touchend', this.handleTouchEnd);
    }

    handleTouchStart({ touches }) {
        window.addEventListener('touchmove', this.handleTouchMove);
        window.addEventListener('touchend', this.handleTouchEnd);

        if (touches.length === 1) {
            this.handleDragStart(touches[0]);
        }
    }

    handleMouseDown(event) {
        window.addEventListener('mousemove', this.handleMouseMove);
        window.addEventListener('mouseup', this.handleMouseUp);

        event.preventDefault();
        this.handleDragStart(event);
    }

    handleTouchMove({ touches }) {
        if (touches.length === 1) {
            this.handleMouseMove(touches[0]);
        }
    }

    handleMouseMove({ clientX, clientY }) {
        const { isDragging } = this.state;
        const { shiftX, shiftY } = this.props;

        if (!isDragging || clientX === undefined || clientY === undefined) {
            return;
        }

        this.setState(({
            originalX,
            originalY,
        }) => ({
            translateX: clientX - originalX + shiftX,
            translateY: clientY - originalY + shiftY,
        }), () => {
            const { onDrag } = this.props;

            if (onDrag) {
                onDrag({ ...this.state, clientX, clientY });
            }
        });
    }

    handleTouchEnd() {
        window.removeEventListener('touchmove', this.handleTouchMove);
        window.removeEventListener('touchend', this.handleTouchEnd);

        this.handleDragEnd();
    }

    handleMouseUp() {
        window.removeEventListener('mousemove', this.handleMouseMove);
        window.removeEventListener('mouseup', this.handleMouseUp);

        this.handleDragEnd();
    }

    handleClick(event) {
        const { onClick } = this.props;

        if (onClick) {
            onClick(
                this.state,
                (newState) => this.setState({
                    ...newState,
                    isDragging: false,
                    translateX: 0,
                    translateY: 0,
                }),
                event,
            );
        }
    }

    handleDragStart({
        clientX,
        clientY,
    }) {
        const { onDragStart } = this.props;

        if (typeof clientX === 'undefined' || typeof clientY === 'undefined') {
            return;
        }

        if (onDragStart) {
            onDragStart();
        }

        this.setState({
            originalX: clientX,
            originalY: clientY,
            isDragging: true,
        });
    }

    handleDragEnd() {
        const { onDragEnd } = this.props;

        onDragEnd(this.state, (
            newState,
        ) => this.setState({
            ...newState,
            isDragging: false,
            translateX: 0,
            translateY: 0,
        }));

        // TO STAY WHERE RELEASED
        // originalX: 0,
        // lastTranslateX: translateX,
        // originalY: 0,
        // lastTranslateY: translateY,

        // TO RETURN INTO INITIAL
        // originalX: 0,
        // lastTranslateX: 0
        // originalY: 0,
        // lastTranslateY: 0
    }

    render() {
        const {
            children,
            handleFocus,
            handleKey,
            draggableRef,
            mix,
        } = this.props;

        return (
            <div
              block="Draggable"
              mix={ mix }
              ref={ draggableRef }
              onMouseDown={ this.handleMouseDown }
              onTouchStart={ this.handleTouchStart }
              onClick={ this.handleClick }
              onContextMenu={ this.handleClick }
              onKeyDown={ handleKey }
              onFocus={ handleFocus }
              tabIndex={ 0 }
              role="button"
              aria-label="Draggable area"
            >
                {children}

            </div>
        );
    }
}

export default DraggableComponent;
