/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable react/jsx-no-useless-fragment */
// import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import { PureComponent } from 'react';

import { ProductType } from '@scandipwa/scandipwa/src/type/ProductList.type';

import './ProductVideo.style';

/** @namespace Pwa/Component/ProductVideo/Component/ProductVideoComponent */
export class ProductVideoComponent extends PureComponent {
    static propTypes = {
        product: ProductType.isRequired
    };

    renderGetId(url) {
        const length = 11;
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        const match = url.match(regExp);

        return (match && match[2].length === length)
            ? match[2]
            : null;
    }

    renderTitleInlineDescription(inputText) {
        const regex = /<h[1-3]>(.*)<\/h[1-3]>/;
        // eslint-disable-next-line fp/no-let
        let textList = [];
        if (inputText && this.isRegex(inputText)) {
            const splitted = inputText.split(regex).filter((data) => data !== '');
            textList = Array.isArray(splitted) ? splitted : [];
        } else {
            textList.push(inputText);
        }

        return textList;
    }

    renderTitleInlineDescriptionArmadi(inputText) {
        const regex = /<br>/;
        // eslint-disable-next-line fp/no-let
        let textList = [];
        if (inputText && this.isRegex(inputText)) {
            const splitted = inputText.split(regex).filter((data) => data !== '');
            textList = Array.isArray(splitted) ? splitted : [];
        } else {
            textList.push(inputText);
        }

        return textList;
    }

    isRegex(inputText) {
        const regex = /<h[1-3]>(.*)<\/h[1-3]>/;
        return inputText.match(regex);
    }

    isRegexArmadi(inputText) {
        const regex = /<h[1-3]>(\n{0,5}.*)<\/h[1-3]>(\n{0,5}.*\n{0,5}.*\n{0,5}.*\n{0,5}.*\n{0,5})/;
        return inputText.match(regex);
    }

    renderInlineDescription() {
        const {
            product: {
                mc_inline_description2,
                mc_inline_description3
            }
        } = this.props;

        const textList = this.renderTitleInlineDescription(mc_inline_description2);

        return (
            <>
                { mc_inline_description2 && this.isRegex(mc_inline_description2) && (textList.length > 0) && (
                    <h2>
                        { parse(textList[0]) }
                    </h2>
                ) }
                <div className="inline_descriptions">
                    { mc_inline_description2 && this.isRegex(mc_inline_description2) && (textList.length > 1) && (
                        <div className="inline_description2">
                            <p>
                                { Boolean(mc_inline_description2)
                                    && parse(textList[1]) }
                            </p>
                        </div>
                    ) }
                    { mc_inline_description2 && !this.isRegex(mc_inline_description2) && (textList.length > 0) && (
                        <div className="inline_description2">
                            <p>
                                { Boolean(mc_inline_description2)
                                    && parse(textList[0]) }
                            </p>
                        </div>
                    ) }
                    { mc_inline_description3 && (
                        <div className="inline_description3">
                            <p>{ Boolean(mc_inline_description3) && parse(mc_inline_description3) }</p>
                        </div>
                    ) }
                </div>
            </>
        );
    }

    renderInlineDescriptionArmadi() {
        const {
            product: {
                mc_inline_description2
            }
        } = this.props;

        const textListArmadi = this.renderTitleInlineDescriptionArmadi(mc_inline_description2);
        const textList = this.renderTitleInlineDescription(mc_inline_description2);

        return (
            <>
                { mc_inline_description2 && this.isRegex(mc_inline_description2) && textListArmadi.length > 0 && (
                    <>
                        { parse(textListArmadi[0]) }
                    </>
                ) }
                <div className="inline_descriptions">
                    { /* eslint-disable-next-line max-len */ }
                    { mc_inline_description2 && this.isRegexArmadi(mc_inline_description2) && textListArmadi.length > 1 && (
                        <div>
                            <>
                                { Boolean(mc_inline_description2)
                                    && parse(textListArmadi[1]) }
                            </>
                        </div>
                    ) }

                    { mc_inline_description2 && !this.isRegexArmadi(mc_inline_description2) && textList.length > 0 && (
                        <div>
                            <>
                                { Boolean(mc_inline_description2)
                                    && parse(textList[0]) }
                            </>
                        </div>
                    ) }
                    { mc_inline_description2 && textListArmadi.length > 2 && (
                        <div>
                            <p>
                                { Boolean(mc_inline_description2)
                                    && parse(textListArmadi[2]) }
                            </p>
                        </div>
                    ) }
                </div>
            </>
        );
    }

    renderVideo_Placeholder() {
        const {
            product: {
                media_gallery_entries
            }
        } = this.props;

        // Ricavo il media che ha come media_type 'external-video'
        const productVideo = media_gallery_entries.filter((media) => media.media_type === 'external-video'
            && !media.types.includes('assembly_video'));
        // Ricavo il media che ha come media.types 'placeholder_video'
        const placeholderVideo = media_gallery_entries.filter((media) => media.types.includes('placeholder_video'));

        if (productVideo.length > 0) {
            const srcIframe = `https://www.youtube.com/embed/${this.renderGetId(productVideo[0].video_content.video_url)}?version=3&loop=1&playlist=${this.renderGetId(productVideo[0].video_content.video_url)}&controls=0`;
            return (
                <div className="ProductVideo">
                    <div block="ProductGallery" elem="VideoBackupImage">
                        <iframe
                          width="100%"
                          height="590"
                          src={ srcIframe }
                          title="YouTube video player"
                          allow="accelerometer; autoplay; clipboard-write;
                          encrypted-media; gyroscope; picture-in-picture"
                        />
                    </div>
                </div>
            );
        }
        if (placeholderVideo.length > 0) {
            return (
                <img src={ placeholderVideo[0].base.url } alt="Placeholder Video" />
            );
        }

        return null;
    }

    render() {
        const {
            product: {
                mc_inline_description2,
                nuovo_template_option
            }
        } = this.props;

        return (
            <>
                { (nuovo_template_option.label !== 'template_elettrodomestici'
                        && nuovo_template_option.label !== 'template_camere'
                        && nuovo_template_option.label !== 'template_armadi2')
                    && (
                        <>
                            { this.renderVideo_Placeholder() }
                            <div className="inline_description2">
                                <p>{ parse(mc_inline_description2) }</p>
                            </div>
                        </>
                    ) }

                { (nuovo_template_option.label === 'template_elettrodomestici'
                    || nuovo_template_option.label === 'template_camere') && (
                    <>
                        { this.renderInlineDescription() }
                    </>
                ) }

                { (nuovo_template_option.label === 'template_armadi2') && (
                    <>
                        { this.renderInlineDescriptionArmadi() }
                    </>
                ) }
            </>
        );
    }
}

export default ProductVideoComponent;
