export const COLORS_POPUP_PREFIX = 'COLORS_POPUP_';
export const MAX_PREVIEW_COLORS = 11;
export const MAX_PREVIEW_COLORS_TEMPLATE_ARMADI = 3;
export const MAX_PREVIEW_COLORS_TEMPLATE_CAMERE = 3;
export const MAX_PREVIEW_COLORS_TEMPLATE_CUCINE = 7;
export const MAX_PREVIEW_COLORS_TEMPLATE_CUCINE_BLOCCATE = 3;
export const MAX_PREVIEW_COLORS_TEMPLATE_DIVANI = 3;
export const MAX_PREVIEW_COLORS_TEMPLATE_LETTI = 3;
export const MAX_PREVIEW_COLORS_TEMPLATE_CAMERETTE = 3;
export const MAX_PREVIEW_COLORS_TEMPLATE_CUCINE_V2 = 3;
export const MAX_PREVIEW_COLORS_TEMPLATE_BAGNI = 3;
export const MAX_PREVIEW_COLORS_TEMPLATE_SOGGIORNI_MADIE = 3;
export const MAX_PREVIEW_COLORS_TEMPLATE_SEDIE_ALTRO = 3;
export const MAX_PREVIEW_COLORS_TEMPLATE_CAMERETTE_BLOCCATE = 3;
export const MAX_PREVIEW_COLORS_TEMPLATE_TAVOLI = 3;
export const MAX_PREVIEW_COLORS_TEMPLATE_MATERASSI = 3;
export const MAX_PREVIEW_COLORS_TEMPLATE_RETI = 3;
export const MAX_PREVIEW_COLORS_TEMPLATE_ELETTRODOMESTICI = 3;
export const FIRST_COLOR_PARAM_NAME = 'fc';
