/* eslint-disable react/prop-types */
/* eslint-disable max-len */
/* eslint-disable react/forbid-prop-types */
import axios from 'axios';
import debounce from 'debounce-promise';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { DEBOUNCE_INTERVAL, MIN_CHARS } from 'Component/ProductCapBox/ProductCapBox.config';
import WarehouseSearchQuery from 'Query/WarehouseSearch.query';
import { CATEGORY_ID_ANGOLO_DELLE_OCCASIONI, QUANTITA_ADO_API } from 'Route/AngoloDelleOccasioni/AngoloDelleOccasioni.config';
import { adoLoading, changeCartQTY, changeQTY } from 'Store/AngoloDelleOccasioni/AngoloDelleOccasioni.action';
import { goToPreviousNavigationState } from 'Store/Navigation/Navigation.action';
import { NavigationType } from '@scandipwa/scandipwa/src/store/Navigation/Navigation.type'

// import { TOP_NAVIGATION_TYPE } from 'Store/Navigation/Navigation.reducer';
import { showNotification } from 'Store/Notification/Notification.action';
import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';
import { prepareQuery } from '@scandipwa/scandipwa/src/util/Query';
import { executeGet } from '@scandipwa/scandipwa/src/util/Request/Request';
import { ONE_MONTH_IN_SECONDS } from '@scandipwa/scandipwa/src/util/Request/Config';

import ProductCapBox from './ProductCapBox.component';

export const McAvailabilityDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/McAvailability/McAvailability.dispatcher'
);

export const WarehouseDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Warehouse/Warehouse.dispatcher'
);

/** @namespace Pwa/Component/ProductCapBox/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    mcAvailability: state.McAvailabilityReducer.mcAvailability,
    product: state.ProductReducer.product
});

/** @namespace Pwa/Component/ProductCapBox/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    updateWarehouse: (warehouse) => WarehouseDispatcher.then(
        ({ default: dispatcher }) => dispatcher.handleData(dispatch, warehouse)
    ),
    showNotification: (type, title) => dispatch(showNotification(type, title)),
    hideActiveOverlay: () => dispatch(hideActiveOverlay()),
    goToPreviousHeaderState: () => dispatch(goToPreviousNavigationState(NavigationType.TOP_NAVIGATION_TYPE)),
    updateProductDetailsQTY: (product) => dispatch(changeQTY(product)),
    adoLoading: (value) => dispatch(adoLoading(value)),
    changeCartQTY: (cartQTY) => dispatch(changeCartQTY(cartQTY))
});

/** @namespace Pwa/Component/ProductCapBox/Container/ProductCapBoxContainer */
export class ProductCapBoxContainer extends PureComponent {
    static propTypes = {
        mix: PropTypes.object,
        mcAvailability: PropTypes.shape({
            description: PropTypes.string,
            timestamp: PropTypes.number
        }).isRequired,
        onChange: PropTypes.func,
        title: PropTypes.string,
        showNotification: PropTypes.func.isRequired,
        hideActiveOverlay: PropTypes.func.isRequired,
        updateWarehouse: PropTypes.func.isRequired,
        product: PropTypes.shape({
            categories: PropTypes.shape({
                id: PropTypes.number.isRequired
            }.isRequired),
            sku: PropTypes.string.isRequired
        }).isRequired,
        updateProductDetailsQTY: PropTypes.func.isRequired,
        adoLoading: PropTypes.func.isRequired
    };

    static defaultProps = {
        title: '',
        mix: {},
        onChange: () => {}
    };

    containerFunctions = {
        loadOptions: this.loadOptions.bind(this),
        onChange: this.handleChange.bind(this)
    };

    componentWillUnmount() {
        const { adoLoading } = this.props;
        adoLoading(false);
    }

    async handleChange(value) {
        const {
            updateWarehouse, onChange, product: { categories, sku }, updateProductDetailsQTY, adoLoading, totals, changeCartQTY
        } = this.props;

        onChange();
        await updateWarehouse(value);
        const isADO = categories && categories.find((elem) => elem.id === CATEGORY_ID_ANGOLO_DELLE_OCCASIONI) !== undefined;
        const postalCode = value?.value.zipcode;
        const warehouseCode = value?.value.code;
        if (isADO) {
            adoLoading(true);
            const response = await axios.post(QUANTITA_ADO_API, {
                skus: sku,
                cap: postalCode,
                warehouse: warehouseCode
            });

            const qty = parseInt(response.data[0].quantity, 10);
            updateProductDetailsQTY(qty);
            adoLoading(false);
        }

        if (totals && totals.items && totals.items.length > 0) {
            const { items } = totals;
            if (postalCode) {
                const skus = items
                    .filter((elem) => (elem?.product?.categories && elem?.product?.categories
                        .find((elem) => elem.id === CATEGORY_ID_ANGOLO_DELLE_OCCASIONI)) !== undefined)
                    .map((elem) => elem.sku).join(',');
                // adoLoading(true);
                const response = await axios.post(QUANTITA_ADO_API, {
                    skus,
                    cap: postalCode,
                    warehouse: ''
                });
                const status_OK = 200;
                if (response.status === status_OK && response.data) {
                    window?.dataLayer?.push({
                        event: 'corner_opportunities_change_store',
                        category: 'corner_opportunities',
                        action: 'change_store',
                        label: value.label || ''
                    });
                    changeCartQTY(response.data);
                }
            }
        }
    }

    loadOptions() {
        const realLoadOptions = (text) => {
            // eslint-disable-next-line no-magic-numbers
            if (text.length <= MIN_CHARS) {
                return Promise.resolve([]);
            }

            const query = [WarehouseSearchQuery.getQuery(text)];
            return new Promise((resolve, reject) => {
                executeGet(prepareQuery(query), 'WarehouseSearch', ONE_MONTH_IN_SECONDS)
                    .then(
                        /** @namespace Pwa/Component/ProductCapBox/Container/executeGet/then */
                        (data) => {
                            resolve(data.mc_search_warehouse.map((option) => ({
                                value: option,
                                label: `${option.zipcode} - ${option.district} - ${option.province}`
                            })));
                        }
                    )
                    .catch(
                        /** @namespace Pwa/Component/ProductCapBox/Container/executeGet/then/catch */
                        (e) => {
                            reject(e);
                        }
                    );
            });
        };

        return debounce(realLoadOptions, DEBOUNCE_INTERVAL, {
            leading: true
        });
    }

    containerProps = () => ({

    });

    render() {
        return (
            <ProductCapBox
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductCapBoxContainer);
