import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import ContentWrapper from 'Component/ContentWrapper';
import ProductSlider from 'Component/ProductSlider';
import { ProductType } from '@scandipwa/scandipwa/src/type/ProductList.type';

import './ProductVariantsSlider.style';

/** @namespace Pwa/Component/ProductVariantsSlider/Component/ProductVariantsSliderComponent */
export class ProductVariantsSliderComponent extends PureComponent {
    static propTypes = {
        product: ProductType.isRequired,
        title: PropTypes.string.isRequired
    };

    render() {
        const { product: { mc_product_variants = [] }, title } = this.props;

        const config = {
            breakpoints: {
                0: {
                    slidesPerView: 1
                },
                1024: {
                    slidesPerView: 3
                }
            },
            theme: 'Related',
            stickers: 'third_level'
        };

        return (mc_product_variants.length > 0) && (
            <ContentWrapper
              mix={ { block: 'ProductVariantsSlider' } }
              wrapperMix={ { block: 'ProductVariantsSlider', elem: 'Wrapper' } }
              label={ __('Linked products') }
            >
                    <p block="ProductVariantsSlider" elem="Title">{ title }</p>
                    <ProductSlider
                      items={ mc_product_variants }
                      config={ config }
                      list="third level products variants"
                    />
            </ContentWrapper>
        );
    }
}

export default ProductVariantsSliderComponent;
