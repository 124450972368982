/* eslint-disable react/forbid-prop-types */
import { getGuestQuoteId } from '@scandipwa/scandipwa/src/util/Cart';
import axios from 'axios';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { AVAILABILITY_INFO_BOX } from 'Component/McAvailability/McAvailability.config';
import { WarehouseDispatcher } from 'Component/ProductCapBox/ProductCapBox.container';
import { changeQTY } from 'Store/AngoloDelleOccasioni/AngoloDelleOccasioni.action';
import { hideActiveOverlay, toggleOverlayByKey } from 'Store/Overlay/Overlay.action';
import { showPopup } from 'Store/Popup/Popup.action';
import { WarehouseType } from 'Type/WarehouseType';

import McAvailability from './McAvailability.component';

export const McAvailabilityDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/McAvailability/McAvailability.dispatcher'
);

export const McRealTimePromoDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/McRealTimePromo/McRealTimePromo.dispatcher'
);

/** @namespace Pwa/Component/McAvailability/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    mcAvailability: state.McAvailabilityReducer.mcAvailability,
    warehouse: state.WarehouseReducer.warehouse,
    storeCode: state.ConfigReducer.code,
    product: state.ProductReducer.product,
    thirdLevelQTY: state.AngoloDelleOccasioniReducer.thirdLevelQTY
});

/** @namespace Pwa/Component/McAvailability/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    getMcAvailability: (options) => McAvailabilityDispatcher.then(
        ({ default: dispatcher }) => dispatcher.handleData(dispatch, options)
    ),
    updateWarehouse: (warehouse) => WarehouseDispatcher.then(
        ({ default: dispatcher }) => dispatcher.handleData(dispatch, warehouse)
    ),
    showPopup: (payload) => dispatch(showPopup(AVAILABILITY_INFO_BOX, payload)),
    showPopup2: (id, payload) => dispatch(showPopup(id, payload)),
    showOverlay: () => dispatch(toggleOverlayByKey(AVAILABILITY_INFO_BOX)),
    hideOverlay: () => dispatch(hideActiveOverlay()),
    updatePromo: (skus, storeCode) => McRealTimePromoDispatcher.then(
        ({ default: dispatcher }) => dispatcher.updatePromo(skus, storeCode, dispatch)
    ),
    changeQTY: (qty) => dispatch(changeQTY(qty))
});

/** @namespace Pwa/Component/McAvailability/Container/McAvailabilityContainer */
export class McAvailabilityContainer extends PureComponent {
    static propTypes = {
        getMcAvailability: PropTypes.func.isRequired,
        sku: PropTypes.string,
        isOnlySct: PropTypes.bool,
        storeCode: PropTypes.string.isRequired,
        mcAvailability: PropTypes.shape({
            description: PropTypes.string,
            timestamp: PropTypes.number
        }).isRequired,
        showPopup: PropTypes.func.isRequired,
        showPopup2: PropTypes.func.isRequired,
        updatePromo: PropTypes.func.isRequired,
        showOverlay: PropTypes.func.isRequired,
        hideOverlay: PropTypes.func.isRequired,
        warehouse: WarehouseType.isRequired,
        updateWarehouse: PropTypes.func.isRequired,
        product: PropTypes.object.isRequired,
        updateProductDetails: PropTypes.func.isRequired,
        changeQTY: PropTypes.func.isRequired
    };

    static defaultProps = {
        sku: undefined,
        isOnlySct: false
    };

    containerFunctions = {
        resetWarehouse: this.resetWarehouse.bind(this),
        updateProduct: this.updateProduct.bind(this)
    };

    componentDidMount() {
        const {
            getMcAvailability,
            sku,
            storeCode,
            isOnlySct: onlySct,
            warehouse,
            updatePromo
        } = this.props;

        updatePromo([sku], storeCode);
        if (sku && warehouse) {
            getMcAvailability({
                sku, onlySct, warehouse: warehouse?.value?.code, zipcode: warehouse?.value?.zipcode
            });
        }
    }

    componentDidUpdate(prevProps) {
        const {
            sku, getMcAvailability, warehouse, isOnlySct: onlySct
        } = this.props;
        const { sku: prevSku, warehouse: prevWarehouse } = prevProps;
        const warehouseChanged = warehouse?.value?.code !== prevWarehouse?.value?.code;
        const skuChanged = sku !== prevSku;
        const hasData = warehouse?.value?.code !== undefined && sku;

        if (hasData && (skuChanged || warehouseChanged)) {
            getMcAvailability({
                sku, onlySct, warehouse: warehouse?.value?.code, zipcode: warehouse?.value?.zipcode
            });
        }
    }

    async resetWarehouse() {
        const { updateWarehouse } = this.props;
        const cartId = getGuestQuoteId();

        if (cartId) {
            await axios.post(`/rest/V1/mc/cap/${cartId}`);
        }
        updateWarehouse(undefined);
    }

    updateProduct() {
        const { changeQTY } = this.props;
        changeQTY(undefined);
    }

    containerProps = () => {
        const {
            sku, mcAvailability, showPopup, showOverlay, hideOverlay, warehouse, product, showPopup2
        } = this.props;

        return {
            warehouse,
            sku,
            mcAvailability,
            showPopup,
            showOverlay,
            hideOverlay,
            product,
            showPopup2
        };
    };

    render() {
        return (
            <McAvailability
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(McAvailabilityContainer);
