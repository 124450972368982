/** @namespace Pwa/Util/isOnlySct/isOnlySct */
export const isOnlySct = (product) => {
    const {
        mc_national_carrier, mc_trasporto_montaggio, mc_ritiro_cliente, mc_venditacc_tablet
    } = product;

    return mc_national_carrier && !mc_trasporto_montaggio && !mc_ritiro_cliente && !mc_venditacc_tablet;
};

export default isOnlySct;
