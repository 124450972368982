/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
import PropTypes from 'prop-types';
import { lazy, PureComponent } from 'react';
import { connect } from 'react-redux';

import { showPopup } from 'Store/Popup/Popup.action';
import { McTechSpecsType } from 'Type/McTechSpecs';
import { ProductType } from '@scandipwa/scandipwa/src/type/ProductList.type';

import './ProductElementsTabs.style';

export const McTechSpecsDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/McTechSpecs/McTechSpecs.dispatcher'
);

// eslint-disable-next-line @scandipwa/scandipwa-guidelines/export-level-one
const TEMPLATES = {
    template_cucine_componibili: lazy(() => import('./templates/cucinecomponibili.component.js')),
    template_cucine_componibiliV2: lazy(() => import('./templates/cucinecomponibili.component.js')),
    template_cucine_bloccate: lazy(() => import('./templates/cucinebloccate.component.js')),
    template_divani: lazy(() => import('./templates/divani.component.js')),
    template_letti: lazy(() => import('./templates/letti.component.js')),
    template_camere: lazy(() => import('./templates/camere.component.js')),
    template_camerette: lazy(() => import('./templates/camerette.component.js')),
    template_bagni: lazy(() => import('./templates/bagni.component.js')),
    template_soggiorni_madie: lazy(() => import('./templates/soggiornimadie.component.js')),
    template_sedie_altro: lazy(() => import('./templates/sediealtro.component.js')),
    template_camerette_bloccate: lazy(() => import('./templates/camerettebloccate.component.js')),
    template_tavoli: lazy(() => import('./templates/tavoli.component.js')),
    template_materassi: lazy(() => import('./templates/materassi.component.js')),
    template_reti: lazy(() => import('./templates/reti.component.js')),
    template_elettrodomestici: lazy(() => import('./templates/elettrodomestici.component.js')),
    template_armadi: lazy(() => import('./templates/armadi.component.js')),
    template_armadi2: lazy(() => import('./templates/armadi.component.js'))
};

/** @namespace Pwa/Component/ProductElementsTabs/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    mcTechSpecs: state.McTechSpecsReducer.mcTechSpecs
});

/** @namespace Pwa/Component/ProductElementsTabs/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    getMcTechSpecs: (sku) => McTechSpecsDispatcher.then(
        ({ default: dispatcher }) => dispatcher.handleData(dispatch, sku)
    ),
    showPopup: (id, payload) => dispatch(showPopup(id, payload))
});

/** @namespace Pwa/Component/ProductElementsTabs/Container/ProductElementsTabsContainer */
export class ProductElementsTabsContainer extends PureComponent {
    static propTypes = {
        product: ProductType.isRequired,
        getMcTechSpecs: PropTypes.func.isRequired,
        sku: PropTypes.string,
        mcTechSpecs: McTechSpecsType.isRequired,
        showPopup: PropTypes.func.isRequired
    };

    static defaultProps = {
        sku: undefined
    };

    containerFunctions = {
        showEntityPopup: this.showEntityPopup.bind(this)
    };

    componentDidMount() {
        const { getMcTechSpecs, product: { sku } } = this.props;

        if (sku) {
            getMcTechSpecs(sku);
        }
    }

    componentDidUpdate(prevProps) {
        const { product: { sku }, getMcTechSpecs } = this.props;
        const { product: { sku: prevSku } } = prevProps;

        if (sku && sku !== prevSku) {
            getMcTechSpecs(sku);
        }
    }

    containerProps = () => {
        // isDisabled: this._getIsDisabled()
    };

    showEntityPopup(code) {
        const {
            showPopup
        } = this.props;

        showPopup(`ENTITY_POPUP_${code}`);
    }

    render() {
        const {
            product: {
                nuovo_template_option
            }
        } = this.props;

        const ProductTabsTemplateComponent = TEMPLATES[nuovo_template_option.label];

        return (
            <ProductTabsTemplateComponent
              { ...this.props }
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductElementsTabsContainer);
