/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
import { connect } from 'react-redux';
import { PureComponent } from 'react';

import {
    GARANZIA_5,
    GARANZIA_10,
    GARANZIA_15
} from 'Component/ProductActions/ProductActions.config';
// import {
//     mapDispatchToProps as sourceMapDispatchToProps,
// } from 'SourceComponent/Product/Product.container';
// import {
//     ProductActionsContainer as SourceProductActionsContainer
// } from 'SourceComponent/ProductActions/ProductActions.container';
import { showPopup } from 'Store/Popup/Popup.action';
import { ProductType } from '@scandipwa/scandipwa/src/type/ProductList.type';

import ProductInfoAggiuntive from './ProductInfoAggiuntive.component';

/** @namespace Pwa/Component/ProductInfoAggiuntive/Container/mapStateToProps */
export const mapStateToProps = () => ({});

/** @namespace Pwa/Component/ProductInfoAggiuntive/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    // ...sourceMapDispatchToProps(dispatch),
    showPopup: (id, payload) => dispatch(showPopup(id, payload))
});

/** @namespace Pwa/Component/ProductInfoAggiuntive/Container/ProductInfoAggiuntiveContainer */
export class ProductInfoAggiuntiveContainer extends PureComponent {
    static propTypes = {
        product: ProductType.isRequired
    };

    containerProps = () => ({
        anniGaranzia: this.getAnniGaranzia()
    });

    getAnniGaranzia() {
        const { product: { gar5anni, gar10anni, gar15anni } } = this.props;

        if (gar5anni > 0) {
            return GARANZIA_5;
        }
        if (gar10anni > 0) {
            return GARANZIA_10;
        }
        if (gar15anni > 0) {
            return GARANZIA_15;
        }

        return 0;
    }

    render() {
        return (
            <ProductInfoAggiuntive
              { ...this.props }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductInfoAggiuntiveContainer);
