import {
    ImageZoomPopupComponent as SourceImageZoomPopup
} from 'SourceComponent/ImageZoomPopup/ImageZoomPopup.component';

import './ImageZoomPopup.override.style';

/** @namespace Pwa/Component/ImageZoomPopup/Component/ImageZoomPopupComponent */
export class ImageZoomPopupComponent extends SourceImageZoomPopup {
    // TODO implement logic
}

export default ImageZoomPopupComponent;
