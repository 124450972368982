/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import {
    CarouselScrollComponent as SourceCarouselScroll
} from 'SourceComponent/CarouselScroll/CarouselScroll.component';
import CSS from 'Util/CSS';
import { createRef } from 'react';

import { CAROUSEL_ITEM_GAP } from 'Component/CarouselScroll/CarouselScroll.config';
import CarouselScrollArrow from 'Component/CarouselScrollArrow';
import CarouselScrollItem from 'Component/CarouselScrollItem';
import { noopFn } from 'Util/Common';

import './CarouselScroll.override.style';

/** @namespace Pwa/Component/CarouselScroll/Component/CarouselScrollComponent */
export class CarouselScrollComponent extends SourceCarouselScroll {
    static defaultProps = {
        showArrow: true,
        showedItemCount: 1,
        onChange: noopFn,
        activeItemId: 0,
        isImageZoomPopupActive: false,
    };

    state = {
        activeItemId: 0,
        firstCarouselItemId: 0,
    };

    itemRef = createRef();

    carouselRef = createRef();

    __construct(props) {
        super.__construct?.(props);
        this.handleArrowClick = this.handleArrowClick.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    setTranslate(nextId) {
        const { children: { length: childrenLength }, showedItemCount } = this.props;

        if (childrenLength <= showedItemCount) {
            return;
        }

        const translate = this.getNextTranslate(nextId);
        CSS.setVariable(this.carouselRef, 'translateX', translate);
    }

    getNextTranslate(nextId) {

        const { showedItemCount, showedActiveItemNr, children: { length: childrenLength } } = this.props;
        const { offsetWidth } = this.itemRef.current;

        // When selected item isnt reached wanted position
        if (nextId < (showedActiveItemNr - 1) || childrenLength <= showedItemCount) {
            return 0;
        }

        const isEndReached = (showedItemCount - showedActiveItemNr) + nextId + 1 > childrenLength;

        const position = isEndReached
            ? childrenLength - showedItemCount
            : nextId - (showedActiveItemNr - 1);

        return `${-position * offsetWidth}px`;
    }

    componentDidMount() {
        // const { activeItemId } = this.props

        const height = '100%';

        CSS.setVariable(this.carouselRef, 'carousel-item-height', height);
    }


    componentDidUpdate(prevProps) {
        const {
            children: { length: prevChildrenLength },
            showedItemCount: prevShowedItemCount,
        } = prevProps;

        const {
            activeItemId,
            children: { length: childrenLength },
            showedItemCount,
        } = this.props;

        const { activeItemId: prevActiveItemId } = this.state;

        if (prevChildrenLength !== childrenLength) {
            this.handleReset();

            return;
        }

        if (activeItemId !== null && activeItemId !== prevActiveItemId) {
            this.handleChange(activeItemId);
        }

        if (prevShowedItemCount !== showedItemCount) {
            const width = this.getCarouselWidth(showedItemCount);

            CSS.setVariable(this.carouselRef, 'carousel-width', width);
            this.updateFirstSlide();
        }
    }

    updateFirstSlide() {
        const { firstCarouselItemId } = this.state;

        const maxId = this.getMaxFirstItemId();

        if (firstCarouselItemId > maxId) {
            this.setTranslate(maxId);
            this.setState({ firstCarouselItemId: maxId });
        }
    }

    getCarouselWidth(showedItemCount) {
        const margin = CAROUSEL_ITEM_GAP;
        const { offsetWidth: cardWidth = 0 } = this.itemRef.current || {};

        return `${(margin + cardWidth) * showedItemCount - margin}px`;
    }

    getMaxFirstItemId() {
        const { children: { length: childrenLength }, showedItemCount } = this.props;

        return Math.max(childrenLength - showedItemCount, 0);
    }

    getNewCarouselItemId(isNextArrow) {
        const { showedItemCount } = this.props;
        const { firstCarouselItemId: prevFirstCarouselItemId } = this.state;

        const scrollStep = Math.ceil(showedItemCount / 2);

        return isNextArrow
            ? Math.min(prevFirstCarouselItemId + scrollStep, this.getMaxFirstItemId())
            : Math.max(prevFirstCarouselItemId - scrollStep, 0);
    }

    handleArrowClick(isNextArrow) {

    const { children: { length: childrenLength } } = this.props;
    const { onChange } = this.props;
    let { activeItemId } = this.state;

    if (isNextArrow) {
        activeItemId = (activeItemId + 1) % childrenLength;
    } else {
        activeItemId = (activeItemId - 1 + childrenLength) % childrenLength;
    }

    onChange(activeItemId);
    this.setState({ activeItemId });

    this.setTranslate(activeItemId);

    }

    handleChange(nextId) {
        const { onChange, showedItemCount } = this.props;
        const { firstCarouselItemId } = this.state;

        onChange(nextId);
        this.setState({ activeItemId: nextId });

        if (nextId < firstCarouselItemId || nextId >= firstCarouselItemId + showedItemCount) {
            const newId = Math.min(this.getMaxFirstItemId(), nextId);

            this.setTranslate(newId);
            this.setState({ firstCarouselItemId: newId });
        }
    }

    handleReset() {
        const { onChange } = this.props;

        const activeItemId = 0;

        CSS.setVariable(this.carouselRef, 'translateX', 0);

        onChange(activeItemId);
        this.setState({ activeItemId });
    }

    renderArrow(isNextArrow = false) {
        const { showArrow, children: { length: childrenLength }, showedItemCount } = this.props;
        const { firstCarouselItemId } = this.state;
        const { activeItemId } = this.state;
        if (!showArrow || childrenLength <= showedItemCount) {
            return null;
        }

        // render hidden arrow to avoid carousel jumping on error hide/appear
        const isInvisible = (!isNextArrow && activeItemId === 0)
        || (isNextArrow && activeItemId >= childrenLength - 1);
        // const isInvisible = (!isNextArrow && firstCarouselItemId === 0)
        //     || (isNextArrow && firstCarouselItemId >= this.getMaxFirstItemId());

        return (
            <CarouselScrollArrow
                isNextArrow={isNextArrow}
                onClick={() => this.handleArrowClick(isNextArrow)}
                isInvisible={isInvisible}
            />
        );
    }

    renderContentItem(child, key) {
        const { activeItemId } = this.state;
      
        return (
            <CarouselScrollItem
                key={key}
                position={key}
                onClick={this.handleChange}
                itemRef={this.itemRef}
                isActive={key === activeItemId}
            >
                {child}
            </CarouselScrollItem>
        );
    }

    renderContent() {
        const { children, isImageZoomPopupActive } = this.props;

        return (
            <div block="CarouselScroll" elem="ContentWrapper" mods={{ isImageZoomPopupActive }}>
                <div block="CarouselScroll" elem="Content">
                    {children.map(this.renderContentItem.bind(this))}
                </div>
            </div>
        );
    }

    render() {
        return (
            <div block="CarouselScroll" ref={this.carouselRef}>
                {this.renderArrow()}
                {this.renderContent()}
                {this.renderArrow(true)}
            </div>
        );
    }
}

export default CarouselScrollComponent;
