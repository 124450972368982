/* eslint-disable fp/no-let */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable max-lines */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable max-len */
import CmsBlock from '@scandipwa/scandipwa/src/component/CmsBlock';
import Popup from '@scandipwa/scandipwa/src/component/Popup';

import AddToCart from 'Component/AddToCart';
import BlackFridayCounter from 'Component/BlackFridayCounter';
import ContentWrapper from 'Component/ContentWrapper';
import FlashSalesIndicator from 'Component/FlashSalesIndicator';
import IfStore from 'Component/IfStore';
import ProductComposizioneTipo from 'Component/ProductCardDetails/Elements/ProductComposizioneTipo.component';
import ProductPrice from 'Component/ProductPrice';
import { CATEGORY_ID_ANGOLO_DELLE_OCCASIONI } from 'Route/AngoloDelleOccasioni/AngoloDelleOccasioni.config';
import {
    ProductActionsComponent as SourceProductActions
} from 'SourceComponent/ProductActions/ProductActions.component';

import './ProductFixedBox.style';

/** @namespace Pwa/Component/ProductFixedBox/Component/ProductFixedBoxComponent */
export class ProductFixedBoxComponent extends SourceProductActions {

    state = {
        showFixedBox: false
    };

    async componentDidMount() {
        const { product: { nuovo_template_option } } = this.props;

        if (((nuovo_template_option.label === 'template_divani')
                || (nuovo_template_option.label === 'template_camere')
                || (nuovo_template_option.label === 'template_cucine_componibiliV2')
                || (nuovo_template_option.label === 'template_cucine_bloccate')
                || (nuovo_template_option.label === 'template_letti')
                || (nuovo_template_option.label === 'template_camerette')
                || (nuovo_template_option.label === 'template_camerette_bloccate')
                || (nuovo_template_option.label === 'template_tavoli')
                || (nuovo_template_option.label === 'template_bagni')
                || (nuovo_template_option.label === 'template_soggiorni_madie')
                || (nuovo_template_option.label === 'template_sedie_altro')
                || (nuovo_template_option.label === 'template_materassi')
                || (nuovo_template_option.label === 'template_reti')
                || (nuovo_template_option.label === 'template_elettrodomestici'))
            && (window.matchMedia('(min-width: 768px)').matches)) {
            // this.setState({ showFixedBox: false });
            window.addEventListener('scroll', this.handleFixedBox);
        }

        const configData = await this.getapiUrl();
        this.setState({ configData });
    }

    async getapiUrl() {
        try {
            // Ottieni i dati dall'API
            const response = await fetch('/rest/V1/configurazioneRate/get/');
            const apiData = await response.json();

            return apiData;
        } catch (error) {
            return 'errore';
            // Gestisci l'errore secondo le tue esigenze
        }
    }

    handleFixedBox = () => {
        const { product: { nuovo_template_option } } = this.props;

        const topScroll = document.body.scrollTop || document.documentElement.scrollTop;

        const addToCartButton = (nuovo_template_option.label === 'template_divani'
            || nuovo_template_option.label === 'template_letti'
            || nuovo_template_option.label === 'template_cucine_bloccate'
            || nuovo_template_option.label === 'template_camere'
            || nuovo_template_option.label === 'template_camerette_bloccate'
            || nuovo_template_option.label === 'template_tavoli'
            || nuovo_template_option.label === 'template_bagni'
            || nuovo_template_option.label === 'template_soggiorni_madie'
            || nuovo_template_option.label === 'template_sedie_altro'
            || nuovo_template_option.label === 'template_materassi'
            || nuovo_template_option.label === 'template_reti'
            || nuovo_template_option.label === 'template_elettrodomestici')
            ? document.querySelector('.ProductActions-PriceAndButton button.AddToCart')
            : document.querySelector('.ProductActions-PriceAndButton a.Button');

        if (addToCartButton) {
            const buttonPosition = addToCartButton.getBoundingClientRect().top;
            if (topScroll > buttonPosition) {
                this.setState({ showFixedBox: true });
            } else {
                this.setState({ showFixedBox: false });
            }
        }
    };

    renderRateFindomestic() {
        const { product, showPopup } = this.props;
        const { configData } = this.state;
        let prf = '';
        let tvei = '';
        if (configData) {
            prf = configData[0];
            tvei = configData[1];
        }
        const { mc_rata_findomestic_information: infoRata, price_range } = product;
        const logo = 'media/logo/logo-fin2.png';
        const valueForMultiplication = 100;
        const {
            maximum_price: {
                final_price: {
                    value: finalPrice
                }
            }
        } = price_range;
        const minValueFinanziamento = 199;
        // eslint-disable-next-line fp/no-let
        let linkToRedirectRata = `https://secure.findomestic.it/clienti/pmcrs/mondoconvenienza/mcommerce/pages/simulatore.html?versione=L&prf=${ prf }&tvei=${ tvei}`;

        if (finalPrice && finalPrice > minValueFinanziamento) {
            linkToRedirectRata = `${linkToRedirectRata}&Importo=${finalPrice * valueForMultiplication }`;
        }

        if (!infoRata) {
            return (
                <p className="rate_findomestic">
                    { __('Payable in installments with Findomestic') }
                </p>
            );
        }

        return (infoRata && (infoRata.durata > 0 || infoRata.durata != null)) && (
            <>
                <IfStore storeCode="default">
                    <button
                      className="rate_findomestic_container"
                      onClick={ () => {
                          showPopup('FINAZIAMENTO_FINDOMESTIC');
                      } }
                    >
                        <p className="rate_findomestic">
                            { __('Payable in installments') }
                        </p>
                        <img src={ logo } alt="" />
                    </button>
                    <Popup
                      id="FINAZIAMENTO_FINDOMESTIC"
                    >
                        <div className="findomestic_popup">
                            <CmsBlock identifier="rate_findomestic_popup" />
                            <a
                              target="_blank"
                              className="Button AddToCart ProductActions-AddToCart"
                              href={ linkToRedirectRata }
                              rel="noreferrer"
                            >
                                { __('Calcola la tua rata') }
                            </a>
                        </div>
                    </Popup>
                </IfStore>
                <IfStore storeCode="spain">
                    <p className="rate_findomestic">
                        { __('Payable in installments with Findomestic') }
                    </p>
                </IfStore>
            </>
        );
    }

    renderMcComposizioneTipo() {
        const { product } = this.props;

        return (
            <section
              block="ProductActions"
              elem="Section"
              mods={ { type: 'mcComposizioneTipo' } }
            >
                <ProductComposizioneTipo product={ product } />
            </section>
        );
    }

    renderCodice() {
        const { product: { sku } } = this.props;

        return (
            <section
              block="ProductActions"
              elem="Section"
              mods={ { type: 'CodiceSku' } }
            >
                <span>
                    { __('codice:') }
                    &nbsp;
                    { sku }
                </span>
            </section>
        );
    }

    getOfferType() {
        const { product: { variants } } = this.props;

        if (variants && variants.length >= 1) {
            return 'https://schema.org/AggregateOffer';
        }

        return 'https://schema.org/Offer';
    }

    getOfferCount() {
        const { product: { variants } } = this.props;

        if (variants && variants.length) {
            return variants.length;
        }

        return 0;
    }

    renderPrezzo() {
    
        const {
            productPrice,
            device: { isMobile }
        } = this.props;

        const offerType = this.getOfferType();
        const offerCount = this.getOfferCount();

        return (
            <div
              block="ProductActions"
              elem="Schema"
              itemType={ offerType }
              itemProp="offers"
              itemScope
            >
                <div
                  block="ProductActions"
                  elem="PriceWrapper"
                >
                    {/* { this.renderConfigurablePriceBadge() } */}
                    { this.renderSchema() }
                    <ProductPrice
                      reverse={ isMobile }
                      isSchemaRequired
                      variantsCount={ offerCount }
                      price={ productPrice }
                      promoPosition="fixedLeft"
                      mix={ { block: 'ProductActions', elem: 'Price' } }
                    />
                    { window.matchMedia('(max-width: 767px)').matches && this.renderCodice() }
                </div>
            </div>
        );
    }

    renderAddToCart() {
        const {
            configurableVariantIndex,
            product,
            product: { categories, mc_black_friday_counter },
            thirdLevelQTY,
            quantity,
            groupedProductQuantity,
            onProductValidationError,
            productOptionsData
        } = this.props;

        if ((mc_black_friday_counter?.items_remaining === 0 || mc_black_friday_counter?.items_remaining === -1)) {
            return (
                <button className="Button AddToCart-Disabled">
                    <span>{ __('Add to cart') }</span>
                </button>
            );
        }
        if ((thirdLevelQTY === 0 || thirdLevelQTY === -1) && categories.find((elem) => elem.id === CATEGORY_ID_ANGOLO_DELLE_OCCASIONI)) {
            return (
                <button className="Button AddToCart-Disabled">
                    <span>{ __('Add to cart') }</span>
                </button>
            );
        }

        // eslint-disable-next-line default-case
        switch (product.mc_vendibile_sito) {
        case 1:
            return (
                <AddToCart
                  product={ product }
                  configurableVariantIndex={ configurableVariantIndex }
                  mix={ { block: 'ProductActions', elem: 'AddToCart' } }
                  quantity={ quantity }
                  fromPDP
                  groupedProductQuantity={ groupedProductQuantity }
                  onProductValidationError={ onProductValidationError }
                  productOptionsData={ productOptionsData }
                  list="third level product page"
                />
            );
        case 0:
            const link = ['/progettare-su-misura-consulenza'];
            switch (product.mc_inline_description) {
            case 'Cucina componibile':
                link[0] = '/progettare-cucina-su-misura-consulenza';
                break;
            case 'Cameretta componibile':
                link[0] = '/progettare-cameretta-su-misura-consulenza';
                break;
            default:
                link[0] = '/progettare-su-misura-consulenza';
            }

            return (
                <>
                    <IfStore storeCode="default">
                        <a
                          block="Button Video"
                          href={ link[0] }
                          onClick={ () => {
                              // EVENTO GA4
                              window?.dataLayer?.push({
                                  event: 'book_appointment_cta',
                                  category: 'book_appointment',
                                  action: 'cta',
                                  label: 'product_page',
                                  product_name: product.name,
                                  category_name: product.mc_techspecs_template,
                                  product_id: product.sku
                              });
                          } }
                        >
                            { __('Prenota un appuntamento') }
                        </a>
                    </IfStore>
                    <IfStore storeCode="spain">
                        <a
                          block="Button Video"
                          href="/a-tu-manera"
                          onClick={ () => {
                              // EVENTO GA4
                              window?.dataLayer?.push({
                                  event: 'book_appointment_cta',
                                  category: 'book_appointment',
                                  action: 'cta',
                                  label: 'product_page',
                                  product_name: product.name,
                                  category_name: product.mc_techspecs_template,
                                  product_id: product.sku
                              });
                          } }
                        >
                            { __('Prenota un appuntamento') }
                        </a>
                    </IfStore>
                </>
            );
        }

        return (
            <AddToCart
              product={ product }
              configurableVariantIndex={ configurableVariantIndex }
              mix={ { block: 'ProductActions', elem: 'AddToCart' } }
              quantity={ quantity }
              fromPDP
              groupedProductQuantity={ groupedProductQuantity }
              onProductValidationError={ onProductValidationError }
              productOptionsData={ productOptionsData }
              list="third level product page"
            />
        );
    }

    renderMcFlashSale() {
        const { product: { sku, mc_flashsale } } = this.props;

        return mc_flashsale && mc_flashsale.items_total > 0 && sku && (
            <div block="ProductActions" elem="FlashSale">
                <FlashSalesIndicator sku={ sku } flashsale={ mc_flashsale } isInfoIcon={ false } />
            </div>
        );
    }

    renderMcBlackFridayCounter() {
        const { product: { sku, mc_black_friday_counter } } = this.props;

        return mc_black_friday_counter && mc_black_friday_counter.items_total > 0 && sku && (
            <div block="ProductActions" elem="BlackFridayCounter">
                <BlackFridayCounter sku={ sku } blackfriday={ mc_black_friday_counter } isInfoIcon={ false } />
            </div>
        );
    }

    // onClickCb() {
    //     const {
    //         showPopup
    //     } = this.props;

    //     showPopup('FINAZIAMENTO_FINDOMESTIC');
    // }

    render() {
        const {
            product: {
                sku,
                mc_flashsale
            }
        } = this.props;
        const { showFixedBox } = this.state;
        const { product } = this.props;

        return (
            <div
              block="ProductFixedBox"
              className={ `fixed_box_${(showFixedBox) || ((product.nuovo_template_option.label !== 'template_divani')
                    && (product.nuovo_template_option.label !== 'template_cucine_componibiliV2')
                    && (product.nuovo_template_option.label !== 'template_letti')
                    && (product.nuovo_template_option.label !== 'template_camere')
                    && (product.nuovo_template_option.label !== 'template_cucine_bloccate')
                    && (product.nuovo_template_option.label !== 'template_camerette')
                    && (product.nuovo_template_option.label !== 'template_camerette_bloccate')
                    && (product.nuovo_template_option.label !== 'template_tavoli')
                    && (product.nuovo_template_option.label !== 'template_bagni')
                    && (product.nuovo_template_option.label !== 'template_soggiorni_madie')
                    && (product.nuovo_template_option.label !== 'template_sedie_altro')
                    && (product.nuovo_template_option.label !== 'template_materassi')
                    && (product.nuovo_template_option.label !== 'template_elettrodomestici'))
                || (window.matchMedia('(max-width: 767px)').matches) ? 'show' : 'hide'}` }
            >
                <ContentWrapper>
                    { window.matchMedia('(min-width: 768px)').matches && (
                        <div className={ `left_content${ (mc_flashsale && mc_flashsale.items_total > 0 && sku) ? '_flash' : '' }` }>
                            <div className="left_content">
                                { this.renderPrezzo() }
                                <div>
                                    { this.renderMcComposizioneTipo() }
                                    { this.renderRateFindomestic() }
                                </div>
                            </div>
                            { (product.nuovo_template_option.label === 'template_divani') && window.matchMedia('(min-width: 812px)').matches && this.renderMcFlashSale() }
                        </div>
                    ) }
                    <div className="right_content">
                        { Object.keys(product).length > 0 && this.renderAddToCart() }
                        { /* { !device.isMobile && (this.renderCodice()) } */ }
                    </div>
                </ContentWrapper>
            </div>
        );
    }
}

export default ProductFixedBoxComponent;
