/* eslint-disable react/jsx-no-bind */
// import PropTypes from 'prop-types';
import Link from '@scandipwa/scandipwa/src/component/Link/Link.component';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { FIRST_COLOR_PARAM_NAME } from 'Component/ColorSelect/ColorSelect.config';

import './ColorSelect.style';

/** @namespace Pwa/Component/ColorSelect/Component/ColorSelectComponent */
export class ColorSelectComponent extends PureComponent {
    static propTypes = {
        options: PropTypes.arrayOf(
            PropTypes.shape({
                value: String,
                label: String,
                image: String,
                url: String
            })
        ).isRequired,
        firstColor: PropTypes.string,
        isWideSelection: PropTypes.bool,
        showColorsPopup: PropTypes.func.isRequired,
        value: PropTypes.string.isRequired,
        maxImages: PropTypes.number.isRequired
    };

    static defaultProps = {
        firstColor: '',
        isWideSelection: false
    };


    componentDidUpdate(prevProps) {
        if (prevProps.options !== this.props.options) {
            
            this.forceUpdate(); 
        }
    }

    renderOption = (option) => {
        const {
            value, isWideSelection, firstColor
        } = this.props;
    
        const selected = value === option.value;
        const fcParam = isWideSelection ? option.value : firstColor;
    
        const url = fcParam ? `${option.url}?${FIRST_COLOR_PARAM_NAME}=${fcParam}` : option.url;
    
        return (
            <div block="ColorSelect" elem="Option" mods={{ selected }}>
                <Link
                    to={ url }
                    onClick={() => {
                        window?.dataLayer?.push({
                            event: 'select_item',
                            action_type: 'proposal',
                            ecommerce: {
                                items: [
                                    {
                                        item_id: option.value,
                                        item_category2: option.label,
                                        affiliation: 'store online',
                                        currency: 'EUR',
                                        item_brand: 'Mondo Convenienza',
                                        quantity: 1
                                    }
                                ]
                            }
                        });
                    }}
                    
                >
                    <img src={option.image} alt={option.label} />
                    <h2>{option.label}</h2>
                </Link>
            </div>
        );
    };
    
    renderHasMore = () => {
        const { showColorsPopup } = this.props;

        return (
            <button block="ColorSelect" elem="HasMore" onClick={ showColorsPopup }>
                <span>{ __('SHOW OTHERS') }</span>
                <div block="ColorSelect" elem="HasMoreChevronContainer">
                    <div block="ColorSelect" elem="HasMoreChevron" />
                </div>
            </button>
        );
    };

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    getVisibleOptions() {
        const { options, firstColor, maxImages } = this.props;

        if (firstColor) {
            const firstColorIdx = options.findIndex((i) => i.value === firstColor);
            const me = options[firstColorIdx];
            options[firstColorIdx] = options[0];
            options[0] = me;
        }

        return options.slice(0, maxImages);
    }

    render() {
        const { options, maxImages } = this.props;
        if (options.length <= 1) {
            return null;
        }

        const visibleOptions = this.getVisibleOptions();
        const hasMore = options.length > maxImages;

        return (
            <div block="ColorSelect" elem="Container">
                { visibleOptions.map(this.renderOption) }
                { hasMore && this.renderHasMore() }
            </div>
        );
    }
}

export default ColorSelectComponent;
