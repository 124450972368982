/* eslint-disable react/jsx-no-bind */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import CmsBlock from 'Component/CmsBlock';
import Link from 'Component/Link';
import Popup from 'Component/Popup';
import {
    GARANZIA_CUSTOM_POPUP,
    METODO_CONSEGNA_CUSTOM_POPUP,
    SERVIZI_CUSTOM_POPUP
} from 'Component/ProductActions/ProductActions.config';
import { ProductType } from '@scandipwa/scandipwa/src/type/ProductList.type';

import './ProductInfoAggiuntive.style';

/** @namespace Pwa/Component/ProductInfoAggiuntive/Component/ProductInfoAggiuntiveComponent */
export class ProductInfoAggiuntiveComponent extends PureComponent {
    static propTypes = {
        product: ProductType.isRequired,
        showPopup: PropTypes.func.isRequired,
        anniGaranzia: PropTypes.number.isRequired
    };

    renderMetodoDiConsegna() {
        const {
            showPopup, product: {
                mc_national_carrier,
                mc_trasporto_montaggio,
                mc_venditacc_tablet,
                mc_ritiro_cliente
            },
            anniGaranzia
        } = this.props;

        const loaderPlaceholderSize = {
            width: '100%',
            height: '200px'
        };

        return (
            <>
                <div
                  block="ProductActions"
                  elem="InformazioniLink"
                  role="button"
                  tabIndex="0"
                  onClick={ () => showPopup(METODO_CONSEGNA_CUSTOM_POPUP) }
                  onKeyDown={ () => showPopup(METODO_CONSEGNA_CUSTOM_POPUP) }
                >
                    <dt block="ProductActions" elem="MetodoDiConsegnaIcon" />
                    <dd
                      block="ProductActions"
                      elem="MetodoDiConsegnaText"
                      className={ !(anniGaranzia > 0) ? 'adapt_width' : '' }
                    >
                        { __('Delivery method') }
                        <p>{ __('You can have your order directly at your home or go and collect it.') }</p>

                    </dd>
                    { window.matchMedia('(max-width: 768px)').matches && (
                        <div className="customButtonProductService" />
                    ) }
                </div>

                <Popup
                  isFullPopup
                  id={ METODO_CONSEGNA_CUSTOM_POPUP }
                >
                    <div className="shipping-list box_full_popup">
                        <h1>{ __('Shipping Methods') }</h1>
                        <div className="shipping-list-flex">
                            { mc_trasporto_montaggio === 1 && (
                                <div className="shipping-list-flex-basis">
                                <CmsBlock
                                  placeHolderSize={ loaderPlaceholderSize }
                                  identifier="popup_metodo_di_consegna_tm"
                                />
                                </div>
                            ) }
                            { mc_national_carrier === 1 && (
                                <div className="shipping-list-flex-basis">
                                <CmsBlock
                                  placeHolderSize={ loaderPlaceholderSize }
                                  identifier="popup_metodo_di_consegna_corriere"
                                />
                                </div>
                            ) }
                            { mc_venditacc_tablet === 1 && (
                                <div className="shipping-list-flex-basis">
                                    <CmsBlock
                                      placeHolderSize={ loaderPlaceholderSize }
                                      identifier="popup_metodo_di_consegna_sottocasatua"
                                    />
                                </div>
                            ) }
                            { mc_ritiro_cliente === 1 && (
                                <div className="shipping-list-flex-basis">
                                <CmsBlock
                                  placeHolderSize={ loaderPlaceholderSize }
                                  identifier="popup_metodo_di_consegna_ritiro"
                                />
                                </div>
                            ) }
                        </div>
                    </div>
                </Popup>
            </>
        );
    }

    renderGaranzia() {
        const {
            showPopup, anniGaranzia
        } = this.props;

        const cms_block = 'popup_anni_garanzia_';

        return (
            anniGaranzia > 0 && (
                <>
                    <div
                      block="ProductActions"
                      elem="InformazioniLink"
                      role="button"
                      tabIndex="0"
                      onClick={ () => showPopup(GARANZIA_CUSTOM_POPUP) }
                      onKeyDown={ () => showPopup(GARANZIA_CUSTOM_POPUP) }
                    >
                        <dt block="ProductActions" elem="GaranziaIcon" />
                        <dd block="ProductActions" elem="GaranziaText">
                            { anniGaranzia > 0 && __('%s year guarantee', anniGaranzia) }
                            <p>{ __('We protect the quality of your product') }</p>
                        </dd>
                        { window.matchMedia('(max-width: 768px)').matches && (
                            <div className="customButtonProductService" />
                        ) }
                    </div>
                    { anniGaranzia > 0 && (
                        <Popup
                          isFullPopup
                          id={ GARANZIA_CUSTOM_POPUP }
                        >
                            <div className="box_full_popup">
                                <CmsBlock
                                  identifier={ cms_block + anniGaranzia }
                                />
                            </div>
                        </Popup>
                    ) }
                </>
            )
        );
    }

    renderFinanziamento() {
        const { anniGaranzia } = this.props;
        return (
            <Link to="/modalita-pagamento/finanziamento" className="ProductActions-InformazioniLink">
                <dt block="ProductActions" elem="FinanziamentoIcon" />
                <dd
                  block="ProductActions"
                  elem="FinanziamentoText"
                  className={ !(anniGaranzia > 0) ? 'adapt_width' : '' }
                >
                    { __('Financing') }
                    <p>{ __('Choose the installment tailored for you') }</p>
                </dd>
                { window.matchMedia('(max-width: 768px)').matches && (
                    <div className="customButtonProductService" />
                ) }
            </Link>
        );
    }

    renderServizi() {
        const { showPopup, anniGaranzia } = this.props;
        const cms_block = 'popup_servizi';

        return (
            <>
                <div
                  block="ProductActions"
                  elem="InformazioniLink"
                  role="button"
                  tabIndex="0"
                  onClick={ () => showPopup(SERVIZI_CUSTOM_POPUP) }
                  onKeyDown={ () => showPopup(SERVIZI_CUSTOM_POPUP) }
                >
                    <dt block="ProductActions" elem="ServiziIcon" />
                    <dd
                      block="ProductActions"
                      elem="ServiziText"
                      className={ !(anniGaranzia > 0) ? 'adapt_width' : '' }
                    >
                        { __('Services') }
                        <p>{ __('Discover all the services at your disposal') }</p>
                    </dd>
                    { window.matchMedia('(max-width: 768px)').matches && (
                        <div className="customButtonProductService" />
                    ) }
                </div>
                <Popup
                  isFullPopup
                  id={ SERVIZI_CUSTOM_POPUP }
                >
                    <div className="box_full_popup">
                        <CmsBlock
                          identifier={ cms_block }
                        />
                    </div>
                </Popup>
            </>
        );
    }

    render() {
        return (
            <dl block="ProductActions" elem="ProductInfoAggiuntive">
                { this.renderMetodoDiConsegna() }
                { this.renderGaranzia() }
                { this.renderFinanziamento() }
                { this.renderServizi() }
            </dl>
        );
    }
}

export default ProductInfoAggiuntiveComponent;
