// import PropTypes from 'prop-types';
import { ProductType } from '@scandipwa/scandipwa/src/type/ProductList.type';
import history from '@scandipwa/scandipwa/src/util/History';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import SelectConfiguration from './SelectConfiguration.component';

/** @namespace Pwa/Component/SelectConfiguration/Container/mapStateToProps */
export const mapStateToProps = (_state) => ({
    // wishlistItems: state.WishlistReducer.productsInWishlist
});

/** @namespace Pwa/Component/SelectConfiguration/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({
    // addProduct: options => CartDispatcher.addProductToCart(dispatch, options)
});

/** @namespace Pwa/Component/SelectConfiguration/Container/SelectConfigurationContainer */
export class SelectConfigurationContainer extends PureComponent {
    static propTypes = {
        product: ProductType.isRequired
    };

    containerFunctions = {
        // getData: this.getData.bind(this)
    };

    containerProps = () => {
        const { product } = this.props;

        return {
            product
        };
    };

    handleChange = (selectedOption) => {
        history.push(selectedOption.url);
    };

    render() {
        return (
            <SelectConfiguration
              onChange={ this.handleChange }
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectConfigurationContainer);
