import { connect } from 'react-redux';

import ProductVideo from './ProductVideo.component';

/** @namespace Pwa/Component/ProductVideo/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    product: state.ProductReducer.product
});

/** @namespace Pwa/Component/ProductVideo/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ProductVideo);
