/* eslint-disable max-len */
/* eslint-disable no-magic-numbers */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import ContentWrapper from 'Component/ContentWrapper';
import ProductSlider from 'Component/ProductSlider';
import { CATEGORY_ID_ANGOLO_DELLE_OCCASIONI } from 'Route/AngoloDelleOccasioni/AngoloDelleOccasioni.config';
import { ProductType } from '@scandipwa/scandipwa/src/type/ProductList.type';

import './LinkedProductsSlider.style';

/** @namespace Pwa/Component/LinkedProductsSlider/Component/LinkedProductsSliderComponent */
export class LinkedProductsSliderComponent extends PureComponent {
    static propTypes = {
        linkedProducts: PropTypes.objectOf(ProductType).isRequired,
        linkType: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired
    };

    render() {
        const {
            title,
            linkType,
            linkedProducts: {
                [linkType]: {
                    items = []
                } = {}
            }
        } = this.props;
        const itemsNoAdo = items.filter((elem) => !elem.categories.find((el) => el.id === CATEGORY_ID_ANGOLO_DELLE_OCCASIONI));

        const config = {
            breakpoints: {
                0: {
                    slidesPerView: 1
                },
                1024: {
                    slidesPerView: 3
                }
            },
            theme: 'Related',
            stickers: 'third_level'
        };

        return (itemsNoAdo.length > 0) && (
            <ContentWrapper
              mix={ { block: 'LinkedProductsSlider' } }
              wrapperMix={ { block: 'LinkedProductsSlider', elem: 'Wrapper' } }
              label={ __('Linked products') }
            >
                <p block="LinkedProductsSlider" elem="Title">{ title }</p>
                <ProductSlider items={ itemsNoAdo } config={ config } list="related products" />
            </ContentWrapper>
        );
    }
}

export default LinkedProductsSliderComponent;
